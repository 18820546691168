@import '../../style/config';


$boxCount: 50;
$baseSize: 20rem;
$tilt: 45;
$colorSet:
    $color-red-30,
    $color-purple-30,
    $color-indigo-30,
    $color-blue-30,
    $color-cyan-30,
    $color-green-30,
    $color-yellow-30,
    $color-orange-30,
    $color-red-30;


.background {
    width: 100%;
    height: 100%;
    position: fixed;
    background: $color-pink-30;
    z-index: -1;
}

.box {
    position: absolute;
    transition: background-color 0.5s ease-out;
    filter: blur(9px);
}

.news-feed-container {
  position: fixed;
  height: 98%;
  width: 8%;
  padding: 4px;
  margin: 4px;
  box-shadow: 5px 5px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  &-left {
    @extend .news-feed-container;
    left: 0%;
  }
  &-right {
    @extend .news-feed-container;
    left: 91%;
  }
}

@media only screen and (max-width: 768px){
  .news-feed-container {
      display: none;
  }
}

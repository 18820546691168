@import '../../style/mixins';
html, body {
    height: 100%;
}

body {

}


// ============= TEXT ========================

.title {
  font-weight: 700;
  color: white;
  vertical-align: top !important;
  margin-bottom: -10px;
  text-align: left;
  font-size: 7vw;
  letter-spacing: -0.1em;
  @include transform(scale(1.8,0.8));
  transform-origin: 0;
}

.link {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3vw;
    letter-spacing: -0.1em;
    transform-origin: 0;
    margin-bottom: 0px;
    @include transform(scale(2,1));
    &-white {
        @extend .link;
        color: white;
    }
}

.card-title {
    @include transform(scale(1.5,1));
    font-size: 6vw;
    letter-spacing: -0.1em;
    transition: all 0.3s ease;
    > a {
        text-decoration: none;
        &:visited {
            color: white;
        }
        &:hover {
            color: white;
            text-decoration: underline dotted white;
        }
        &:active {
            color: white;
            filter: blur(2px);
        }
        &:link {
            color: white;
        }
    }
}

.card-subtitle {
    @include transform(scale(3,1));
    font-size: 4vw;
    letter-spacing: -0.1em;
    font-weight: 700;

}

.card-footer {
    font-size: 1vw;
    letter-spacing: -0.1em;
    @include transform(scale(2.5,1.8));
}

.link-animation {
    color: white;
    &:hover {
      color: rgb(218, 165, 21);
      text-decoration: none;
    }
    &-full {
        @extend .link-animation
    }
    &-rev {
      @extend .link-animation;
      &:hover {
          color: rgb(0, 153, 255);
          text-decoration: none;
      }
    }
}

.link-animation::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(218, 165, 21);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.link-animation-rev::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 153, 255);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.link-animation-full::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  background-color: rgb(218, 165, 21);;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.link-white {
    transition: all 0.3s ease;
}

.link-animation:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.link-animation-rev:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.social-link {
    color: white;
    transition: all 0.3s ease;

    &-left {
        @extend .social-link;
        margin-left: -10%;
    }
    &-right {
        @extend .social-link;
    }
    &:hover {
        filter: blur(4px);
    }
}

// ============= POSITIONING ==================

.flip-container {
    height: 100vh;
    padding-bottom: 15px;
}

.title-container {
    margin-bottom: -10px;
}
.t-d {
    width: 6vw;
    margin-top: 20px;
}

.body-container {
    height: auto;
}

.flip-img {
    margin-left: auto;
    width: 100%;
    height: 74vh;
}

.bryson-graphic {
    height: 70vh;
    margin-top: 3vh;
    transition: all 0.5s ease;
    &:hover {
        filter: blur(4px)
    }
}

.social-link-container {
    margin-top: 3vh;
    text-align: center;
}

// ============= ANIMATIONS ==================

.flip-img {

}

.flip-card {
  background-color: transparent;
  transition: all 0.3s ease;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  &:hover {
      background-color: white;
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left !important;
  animation: img_rotation 10s infinite;
  transform-style: preserve-3d;
}


/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

.flip-link {
    transition: all 0.2s ease;
}

.flip-link:hover {
    filter: blur(2px);
}

.card-text {
  position: relative;
  text-align: center;
  width: 50%;
  margin: auto;
  top: 50%;
}


@keyframes img_rotation {
  0% {
    transform: rotateY(0deg);
  }
  10% {
    transform: rotateY(0deg);
  }
  30% {
    transform: rotateY(180deg);
  }
  70% {
    transform: rotateY(180deg);
  }
  90% {
    transform: rotateY(359deg);
  }
  100% {
    transform: rotateY(359deg);
  }
}


@media only screen and (max-width: 768px) {
    .mesh-bar-container {
      display: none;
    }
    .bryson-graphic {
      display: none;
    }
    .social-link-left {
        margin-left: 0px;
    }
    .social-link-container {
        bottom: 25px;
        position: fixed;
    }
    .title {
        font-size: 11vw;
    }
    .link {
        font-size: 5vh;
    }
    .card-title {
        font-size: 6vh;
        padding-top: 100px;
    }
    .card-subtitle {
        font-size: 4.5vh;
    }
    .t-d {
        display: none;
    }
    .link-animation::before {
        width: 48%;
    }
    .flip-img {
        width: 90vw;
        height: auto;
    }
    .card-footer {
        font-size: 2vw;
    }
}

@mixin breakpoint-custom-up($min) {
  @media all and (min-width: $min) {
    @content;
  }
}

@mixin breakpoint-sm-up {
  @include breakpoint-custom-up($screen-sm-min) {
    @content;
  }
}

@mixin breakpoint-md-up {
  @include breakpoint-custom-up($screen-md-min) {
    @content;
  }
}

@mixin breakpoint-lg-up {
  @include breakpoint-custom-up($screen-lg-min) {
    @content;
  }
}

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

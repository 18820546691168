@import './style/mixins';

body {
    overflow-x: hidden;
    margin: 0;
}

html, body {
    height: 100%;
    font-family: 'Cormorant Garamond', serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 768px){
    html, body {
      overflow-x: hidden;
    }
    body {
      position: relative
    }
}

.react-icons {
  vertical-align: middle;
}

@import '../../style/mixins';

.content-container {
    border-style: solid;
    border-color: black;
    border-width: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 5px 5px;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
    &-header {
        @extend .content-container;
        padding: 0px !important;
    }
}


.sc-container {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
  font-weight: 100;
}

.header {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 100;
  font-size: 7vw;
  letter-spacing: -0.1em;
  @include transform(scale(1.8,0.8));
  padding: 0px;
  > a {
      text-decoration: none;
      color: inherit;
      &:hover {
          color: inherit;
          text-decoration: none;
      }
  }
}

@media only screen and (max-width: 768px) {
  .header {
      font-weight: 500;
      font-size: 9vw;
  }
}

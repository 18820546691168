.rev-page-container {
    text-align: 'center';
    max-height: 100%;
}

.song-text {
    color: white;
    text-align: right;
    font-size: 26px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: -5px !important;
    a {
        color: white;
        &:hover {
            text-decoration: none;
            color: white;
        }
    }
}

.song-text-container {
    position: absolute;

    @media screen and (orientation: portrait)
    {
        right: 40px;
        bottom: 10vh;
    }
    @media screen and (orientation: landscape)
    {
        bottom: 7%;
        right: 10vw;
    }
}

.social-media-links-container {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    flex-direction: column;
    a {
        font-size: 50px;
        margin-left: 20px;
        color: white;
        border-radius: 50%;
        &:hover{
            text-decoration: none;
            color: white;
        }
    }

    @media screen and (orientation: portrait)
    {
        flex-direction: row;
    }
}

#spotify:hover {
    --shadow-color-full: rgba(30, 215, 96, 0.7);
    --shadow-color-hide: rgba(30, 215, 96, 0); 
    animation: pulse 1s linear;
}

#matter:hover {
    --shadow-color-full: rgba( 137, 106, 250, 0.7);
    --shadow-color-hide: rgba( 137, 106, 250, 0); 
    animation: pulse 1s linear;
}

#audius:hover {
    --shadow-color-full: rgba( 134, 42, 231, 0.7);
    --shadow-color-hide: rgba( 134, 42, 231, 0); 
    animation: pulse 1s linear;
}

#instagram:hover {
    --shadow-color-full: rgba( 235, 75, 93, 0.7);
    --shadow-color-hide: rgba( 235, 75, 93, 0); 
    animation: pulse 1s linear;
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 var(--shadow-color-full);
        background-color: var(--shadow-color-full);
        color: black;
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 200px var(--shadow-color-hide);
        background-color: var(--shadow-color-hide);
        color: white;
    }
}

.mobile-links {
    display: hidden;
    
}

@media only screen and (max-width: 768px) {
    .mobile-links { 
        display: flex;
        flex-direction: column;    
        left: 40px;
        position: absolute;
        top: 100px;
    }
    .mobile-link {
        font-size: 26px;
        text-align: left;
        color: white;
        margin-bottom: 10px;
        &-purple {
            @extend .mobile-link;
            font-size: 30px;
            font-weight: 1000;
            &:hover {
                color: #f837eb;
                text-decoration: none;
            }
        }
        &-mid {
            @extend .mobile-link;
            &:hover {
                color: #463fc7;
                text-decoration: none;
            }
        }
        &-blue {
            @extend .mobile-link;
            &:hover {
                color: #50EEFF;
                text-decoration: none;
            }
        }
        
    }

    .mobile-link::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 90%;
        top: 200px;
        left: -40px;
        transform: scaleY(0);
        transition: all 0.3s ease-in-out 0s;
    }

    .mobile-link-purple::before {
        background-color: #E600D6;
    }

    .mobile-link-mid::before {
        background-color: #463fc7;
    }

    .mobile-link-blue::before {
        background-color: #50EEFF;
    }

    .mobile-link:hover::before {
        visibility: visible;
        transform: scaleY(5);
    }
}


